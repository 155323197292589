import './App.css';
import {Landing} from "./components/Landing";
import Timeline from "./components/Timeline";
import Footer from "./components/Footer";
import Images from "./components/Images";

function App() {
  return (
    <>
      <Landing />
      <Timeline />
      <Images />
      <Footer />
    </>
  );
}

export default App;
