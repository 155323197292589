import React from 'react'
import styled from 'styled-components'
import img1 from '../assets/na-pomolu.png'

const CornerLeft = styled.img`
  position: absolute;
  left: 0;
  top: 0;
`

const CornerRight = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  
  transform: scale(-1);
`

const Title = styled.h1`
  width: 100vw;
  display: grid;
  place-items: center;
  color: white;
  font-size: min(15vw, 200px);
  transform: scale(1);
  transition: transform 1s ease-in;
  margin-bottom: 0;
  margin-top: 3rem;
  text-align: center;

  background: linear-gradient(143.01deg, #ECD9B4 10.97%, #D1965E 112.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;


`

const Date = styled.h2`
  width: 100vw;
  display: grid;
  place-items: center;
  color: white;
  font-size: 6vw;
  margin-top: 0;

  background: linear-gradient(143.01deg, #ECD9B4 10.97%, #D1965E 112.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  
`

const Container = styled.div`
  position:relative;
  box-sizing: border-box;
  margin: 0rem;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;


`

const Image = styled.img`
  width: min(100vw, 1200px);
  margin: auto;
`

export const Landing = () => {
  return <Container>
    <Title>Anja & Klemen</Title>
    <Date>3. 9. 2022</Date>
    <Image src={img1}/>


  </Container>
}

