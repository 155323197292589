import React from "react";
import styled, {keyframes} from "styled-components";

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;
`
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(5deg);
  }
  95% {
    transform: rotate(-1deg);
  }
  98% {
    transform: rotate(1deg);
  }
`;
const Link = styled.a`
  text-decoration: none;
  color: #D1965E;
  font-weight: bold;
  font-size: 2rem;
  max-width: min(500px, 80vw);
  text-align: center;
  background: white;
  padding: 15px;
  margin: 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.07);
  animation: ${rotate} 5s linear infinite;
`


const ImagesUpload = props => <Wrapper>
  <Link href={"https://drive.google.com/drive/folders/1QT_3yEx0zxe9Mkgwj15aZ2O7GRA_lutR"}>Tukaj lahko naložite in pogledate fotke s poroke</Link>
</Wrapper>

export default ImagesUpload
