import React, {useState} from "react";
import styled from "styled-components";
import dvorec from "../assets/dvorec-lisičje.svg"
import rozka from "../assets/rozka.svg"
import separator from "../assets/separator-fancy-1.svg"

const Container = styled.div`
  box-sizing: border-box;
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  margin: auto;
  flex-wrap: wrap;
  background: white;

`

const Image = styled.img`
  max-width: 80vw;
  height: 30vh;
`

const Pair = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
`

const Text = styled.div`
  max-width: 400px;
  width: 100%;
  color: #555555;
  font-family: 'Lora', serif;

  overflow-wrap: break-word;
  h1 {
    font-size: min(4rem, 15vw);
    text-align: center;
  }
  a {
    color: #555555;
    font-weight: bold;
    overflow-wrap: break-word;
  }
  
  p {
    font-size: 1.3rem;
  }
  
`

const Spacer = styled.div`
  height: 10rem;

  @media (max-width: 800px) {
    height: 6rem;
  }
`

const Title = styled.h2`
  width: 100vw;
  display: grid;
  place-items: center;
  background: white;
  font-size: max(3rem, 6vw);
  margin: 0;
  position: relative;
  color: #555555;

  
`
const Separator = styled.img`
  position: relative;
  top: 3px;
  width: 100%;
  height: fit-content;
  
  object-fit: fill;
`
const SeparatorBottom = styled.img`
  position: relative;
  top: -3px;
  width: 100%;
  height: fit-content;
  
  object-fit: fill;
  transform: rotate(180deg);
  
`

const Timeline = () => {
  return <div style={{position: "relative", width: "100vw"}}>
    <Spacer />
    <Separator src={separator} />
    <Title>Kje in kdaj</Title>
    <Container>
      <Pair>
        <Image src={dvorec} />
        <Text>
          <h1>POROKA</h1><br/>
          <h2>dvorec Lisičje ob 13:00</h2>
          <p>
            lokacija: <a href={'https://goo.gl/maps/e3P5cmu7iuG4DxMk8'}>https://goo.gl/maps/e3P5cmu7iuG4DxMk8</a><br/><br/>
          </p>
          <p>
            Civilna poroka se bo odvila ob <strong>13.00</strong>, zato prosiva svate, da se zberete pri dvorcu vsaj 15 minut prej, kjer si lahko najdete mesto v atriju dvorca in pričakate najin prihod.
          </p>

          <p>
            <strong>Kje parkiram na dvorcu Lisičje?</strong><br/>
            Ko se z avtom pripelješ do dvorca, bo na levi strani vidna pot, ki naj ostane prehodna, zato parkiraj svoj avto na desno stran, med dvorec in razpadajočo hišo.
          </p>

          <p>
            Na Lisičju bo poroki sledila pogostitev s šampanjcem in prigrizki, prizorišče pa moramo zapustiti do tretje ure.
          </p>
          <p>
            Pot z Lisičja do Rozke traja 1 uro in 5 minut!
          </p>
          <p>
            Pred odhodom ne pozabi okrasiti avta z mašno in vzeti vreče bombonov za radovedneže ob poti do svatbe. Vse potrebno najdeš v gajbici ob vhodu v dvorec.
          </p>
        </Text>
      </Pair>
      <Pair>
        <Image src={rozka} />
        <Text>
          <h1>SVATBA</h1><br/>
          <h2>Hotel Rozka ob 16:00</h2>
          <p>
            lokacija: <a href={'https://goo.gl/maps/7GQRzAq1q5ne31YA9'}>https://goo.gl/maps/7GQRzAq1q5ne31YA9</a>
          </p>
          <p>
            Svatba se začne ob 16.30 z aperitivom na terasi hotela Rozka na Krvavcu.
          </p>
          <p>
            Kako pridem do hotela Rozka?
            Ko bo procesija avtomobilov 10 minut pred prihodom do zapornice, bo Amanda obvestila hotel Rozko, da se svatje Lampretovih zapeljete do gostišča. Če se odtujiš od procesije, pokliči na telefonsko številko 04 201 2920 in povej, da si naš, da te spustijo naprej. 😉
          </p>
          <p>
            Opozorilo; pot ni asfaltirana, parkiraš pa lahko ali pred Rozko ob poti, ali pa se zapelješ mimo hotela in parkiraš zadaj.
          </p>
          <p>
            <strong>Lahko prespim na Krvavcu?</strong><br/>

            Seveda! Prenočišč je dovolj za vse, cena nočitve z zajtrkom pa znaša 35 € na osebo. Če nimaš treznega voznika, bo to odlična rešitev. Vse, kar moraš storiti, je, da nama predčasno (najkasneje 1 teden prej) sporočiš, da nameravaš prespati in bova zate naredila rezervacijo.
          </p>
          <p>
            Prijateljski nasvet; če se odločiš prespati, boš imel/a dovolj časa po prihodu na Rozko, da se na hitro osvežiš v svoji sobi, morda preoblečeš ali preobuješ, in pričakaš na najin prihod ob aperitivu (midva se greva slikat vmes).
          </p>
          <p>
            Poglej si vreme, da te ne bo zvečer zeblo!
          </p>
        </Text>
      </Pair>
    </Container>
    <Spacer />
    <SeparatorBottom />
  </div>
}

export default Timeline
