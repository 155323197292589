import React from 'react'
import styled from "styled-components";
import img1 from '../assets/rožica.png'

const Image = styled.img`
  width: min(500px, 80vw);
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
`

const Text = styled.h1`
  display: grid;
  place-items: center;
  text-align: center;
  color: white;
  font-size: max(5rem, 10vw);
  margin-bottom: 0;
  margin-top: 3rem;

  background: linear-gradient(143.01deg, #ECD9B4 10.97%, #D1965E 112.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`

const Footer = () => <Container>
  <Image src={img1}/>
  <Text>Se vidimo!</Text>
</Container>

export default Footer
